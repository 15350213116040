import React from 'react';
import Image from '@/components/base/image';
import { IPromoBannerProps } from './types';
import { motion } from 'framer-motion';
import useDeviceType from '@/hooks/useDeviceType';

const animations = {
  container: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.15,
        duration: 0.3,
      },
    },
  },
  item: {
    hidden: {
      x: '-50%',
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  },
};

const PromoBanner: React.FC<IPromoBannerProps> = ({
  children,
  backgroundColor,
  desktopImage,
  mbDesktop = 100,
  mtDesktop = 100,
  mtLaptop = 100,
  mbLaptop = 100,
  mbMobile = 30,
  mtMobile = 30,
}) => {
  const deviceType = useDeviceType();

  const style = {
    '--bg': backgroundColor,
    marginTop:
      deviceType === 'mobile'
        ? mtMobile
        : deviceType === 'laptop'
        ? mtLaptop
        : mtDesktop,
    marginBottom:
      deviceType === 'mobile'
        ? mbMobile
        : deviceType === 'laptop'
        ? mbLaptop
        : mbDesktop,
  } as React.CSSProperties;

  return (
    <motion.section
      className="promo-banner"
      variants={animations.container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.5 }}
      style={style}
    >
      <motion.div className="promo-banner-space" />
      <motion.div className="promo-banner-image" variants={animations.item}>
        <Image {...desktopImage} />
      </motion.div>
      <motion.div className="promo-banner-container" variants={animations.item}>
        {children}
      </motion.div>
    </motion.section>
  );
};

export default PromoBanner;
